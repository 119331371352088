import React, { useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { LandingLayout } from "@layout"
import SEO from "@components/seo"
import { Container } from "react-bootstrap"
import {
  MainBlockTitleUnderline,
  LandingJoinColony,
  MainBlockTestimonials,
} from "@components/main-blocks"
import "@styles/pages/_content.scss"

// Impact Create Link Advanced Settings
// Sub ID 1: make-money-taking-surveys
// Shared Id: web
// Property: ATM.com (website)
// https://help.impact.com/en/support/solutions/articles/48001238125#add-static-parameters-via-the-web-extension-0-1

const ContentPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { slug: { eq: "/make-money-paid-surveys/" } }
      ) {
        html
        frontmatter {
          title
          subtitle
          last_updated_at
          image
          tags
        }
      }
    }
  `)

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  useEffect(() => {
    const markdownContainer = document.querySelector(".markdown-content")

    const handleLinkClick = (event) => {
      const target = event.target
      if (target.tagName === "A" && target.href.includes("/go?source=")) {
        window.dataLayer.push({
          event: "conversion",
          conversionType: "linkClick",
          conversionUrl: target.href,
        })
      }
    }

    if (markdownContainer) {
      const links = markdownContainer.querySelectorAll("a")
      links.forEach((link) => {
        link.setAttribute("target", "_blank")
        link.setAttribute("rel", "noopener noreferrer")
      })

      markdownContainer.addEventListener("click", handleLinkClick)
    }

    return () => {
      if (markdownContainer) {
        markdownContainer.removeEventListener("click", handleLinkClick)
      }
    }
  }, [])

  return (
    <LandingLayout>
      <SEO
        overrideTitleTemplate="%s"
        title={frontmatter.title}
        description={frontmatter.description}
        image={frontmatter.image}
      />
      <div className={`main-block-content-container`}>
        <Container as="article" fluid="md">
          <header>
            <h1 className="title">{frontmatter.title}</h1>
            <h2 className="subtitle">{frontmatter.subtitle}</h2>
            <div className="tags">
              {frontmatter.tags &&
                frontmatter.tags.map((tag) => (
                  <span key={tag}>
                    <Link
                      to={`/colony-guides/?tag=${encodeURIComponent(
                        tag
                      )}#filter-posts`}
                    >
                      {tag}
                    </Link>
                  </span>
                ))}
            </div>
            <div className="publish-info">
              <span className="date">
                Last updated {frontmatter.last_updated_at}
              </span>
              <span className="separator">|</span>
              <span className="author">
                By <Link to="/colony-guides/">Penny & Buck</Link>
              </span>
            </div>
            <div className="image">
              <img src={frontmatter.image} alt={frontmatter.title} />
            </div>
            <div className="disclosure">
              <p>
                ATM.com&reg; may receive affiliate compensation for purchases
                made from affiliate sellers when you click on their links on our
                site.
              </p>
            </div>
          </header>
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Container>
      </div>
      <MainBlockTitleUnderline className="white">
        Try the app designed to earn you <span>more money</span>
      </MainBlockTitleUnderline>
      <MainBlockTestimonials h1="App Reviews" />
      <LandingJoinColony
        h1="Get Paid To Take Surveys"
        h2="Make Extra Money And Get Real Cash Rewards Not Points"
        buttonText="Get The App"
      />
    </LandingLayout>
  )
}

export default ContentPage
